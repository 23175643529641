var app = angular.module('NodenApp', ['ui.bootstrap', 'ui.calendar', 'ngResource', 'ui.bootstrap.showErrors','xeditable', 'ui.bootstrap.datetimepicker', 'ngSanitize', 'ngFileUpload', 'bootstrapLightbox', 'cwill747.phonenumber']);
app.factory('Job', function($resource) {
    return $resource('/jobs/:id',{ id: '@id' }, {
        update: {
            method: 'PUT'
        },
        addItem: {
            url: '/jobs/:job_id/items',
            method: 'PUT'
        },
        notify: {
            url: '/jobs/:job_id/notify',
            method: 'POST'
        },
        email: {
            url: '/jobs/:job_id/email/:what',
            method: 'POST'
        },
        pdf: {
            url: '/jobs/:job_id/:what',
            method: 'GET'
        }
    });
});

app.factory('JobItem', function($resource) {
    return $resource('/jobs/:job_id/items/:id',{ id: '@id', job_id: '@job_id' }, {
        update: {
            method: 'PUT'
        },
        search: {
            method: 'GET',
            url: '/jobs/items/search',
            params: {
                show: '@t'
            },
            isArray:true
        }
    });
});

app.factory('Staff', function($resource) {
    return $resource('/jobs/:job_id/staff/:id',{ id: '@id', job_id: '@job_id' }, {
        update: {
            method: 'PUT'
        },
        search: {
            method: 'GET',
            url: '/staff/search',
            params: {
                show: '@t'
            },
            isArray:true
        },
        all: {
            method: 'GET',
            url: '/staff',
            isArray:true
        }
    });
});

app.factory('Customer', function($resource) {
    return $resource('/customers/:id',{ id: '@id' }, {
        update: {
            method: 'PUT'
        },
        search: {
            method: 'GET',
            url: '/customers/search',
            params: {
                show: '@t'
            },
            isArray:true
        },
        guitars: {
            method: 'GET',
            url: '/customers/:id/guitars',
            isArray:true
        }
    });
});


app.factory('Guitar', function($resource) {
    return $resource('/jobs/:job_id/guitars/:id',{ id: '@id', job_id: '@job_id' }, {
        update: {
            method: 'PUT'
        },
        search: {
            method: 'GET',
            url: '/guitars/search',
            params: {
                show: '@t',
                type: 'manufacturer'
            },
            isArray:true
        },
        searchModels: {
            method: 'GET',
            url: '/guitars/search',
            params: {
                show: '@t',
                type: 'model'
            },
            isArray:true
        }
    });
});


app.controller( 'AppCtrl', function( $scope, Job ) {

});
app.controller( 'JobCtrl', function( $scope, $uibModal, $filter, $attrs, Job, JobItem, Customer, Staff, Guitar, $rootScope, Upload, Lightbox ) {

    $scope.id = $attrs.jobId;
    $scope.emailTemplate = [];
    $scope.emailTemplate['invoice'] = $attrs.emailInvoiceTemplate;
    $scope.emailTemplate['quote'] = $attrs.emailQuoteTemplate;
    $scope.version = '1.3';
    $scope.eventSources = [];
    $scope.customer = undefined;
    $scope.jobStatuses =  [
        {value: 'Quote', text: 'Quote'},
        {value: 'Complete', text: 'Complete'},
        {value: 'Active', text: 'Active'},
        {value: 'Pending', text: 'Pending'}
    ];
    $scope.invoiceStatuses =  [
        {value: 'Not paid', text: 'Not paid'},
        {value: 'Paid', text: 'Paid'},
        {value: 'Part paid', text: 'Part paid'}
    ];
    $scope.guitarStatuses =  [
        {value: 'Picked up', text: 'Picked up'},
        {value: 'Transit', text: 'Transit'},
        {value: 'Workshop', text: 'Workshop'}
    ];
    $scope.isLoaded = false;
    $scope.isUploading = false;
    $scope.saveText = '';
    $scope.saving = false;
    $scope.uploadingPercent = 0;
    $scope.media = [];

    $scope.updateSaveText  = function(text) {
        $scope.saveText = text;
    };

    $scope.startSaving = function() {
        $scope.updateSaveText('Saving...');
        $scope.saving = true;
    };

    $scope.stopSaving = function() {
        $scope.updateSaveText('Last saved: '+moment().format('h:mm:ss a'));
        $scope.saving = false;
    };

    $scope.errorSaving = function(e) {
        var text = (e.statusText) ? ': '+ e.statusText : '';
        toastr.error('There was a problem'+text, 'Error');
        $scope.updateSaveText('Error saving'+text);
        $scope.saving = false;
    };

    $scope.closeDatepicker = function(){
        document.getElementById('app').click()
    };

    $scope.$watch('files', function () {
        $scope.upload($scope.files);
    });
    $scope.$watch('file', function () {
        if ($scope.file != null) {
            $scope.files = [$scope.file];
        }
    });

    $scope.upload = function (files) {
        if (files && files.length) {
            for (var i = 0; i < files.length; i++) {
                var file = files[i];
                if (!file.$error) {
                    $scope.isUploading = true;
                    Upload.upload({
                        url: '/upload-media',
                        data: {
                            job_id: $scope.id,
                            file: file
                        }
                    }).then(function (resp) {
                        $scope.isUploading = false;
                        var media = resp.data;
                        $scope.media.push(media);

                    }, null, function (evt) {
                        var progressPercentage = parseInt(100.0 *
                            evt.loaded / evt.total);
                        $scope.uploadingPercent = progressPercentage;
                    });
                }
            }
        }
    };


    $scope.openLightboxModal = function (index) {
        //console.log(index, $scope.media);
        Lightbox.openModal($scope.media, index);
    };


    $scope.showJobStatus = function() {
        var selected = $filter('filter')($scope.jobStatuses, {value: $scope.job.status});
        return ($scope.job.status && selected.length) ? selected[0].text : 'Not set';
    };
    $scope.showInvoiceStatus = function() {
        var selected = $filter('exact')($scope.invoiceStatuses, {value: $scope.job.invoice_status});
        return ($scope.job.invoice_status) ? selected[0].text : 'Not set';
    };
    $scope.showGuitarStatus = function() {
        var selected = $filter('filter')($scope.guitarStatuses, {value: $scope.job.guitar_status});
        return ($scope.job.guitar_status && selected.length) ? selected[0].text : 'Not set';
    };

    $scope.$watch('customer', function() {
        $scope.hasCustomer = (typeof $scope.customer === 'object') && $scope.customer.id > 0;
        if($scope.hasCustomer == true && $scope.customer.id > 0 && $scope.job.customer_id != $scope.customer.id) {
            $scope.job.customer_id = $scope.customer.id;
            $scope.updateJob();
        }
    }, true);

    $scope.guitars = [];

    $scope.job = Job.get({ id: $scope.id }).$promise.then(function(job) {
        $scope.job = job;
        $scope.isLoaded = true;
        $scope.job.amount_paid = parseFloat(job.amount_paid);
        $scope.guitars = job.guitars;
        $scope.media = job.media;
        setTimeout(function() {
            $('textarea').trigger('input.autosize');
        }, 500)
        if(job.customer_id > 0) {
            Customer.get({id: job.customer_id}).$promise.then(function (customer) {
                $scope.customer = customer;
                Customer.guitars({id: job.customer_id}).$promise.then(function (guitars) {
                    $scope.customerGuitars = guitars;
                });
            });
        }
    });
    $scope.staffs = Staff.query({ job_id: $scope.id }).$promise.then(function(staffs) {
        $scope.staffs = staffs;
    });

    $scope.allStaff = Staff.all().$promise.then(function(items) {
        $scope.allStaff = items;
    });



    $scope.items = JobItem.query({ job_id: $scope.id }).$promise.then(function(items) {
        $scope.items = items;
    });

    $scope.isComplete = function() {
        return $scope.job && ($scope.job.status == 'Complete')
    };

    $scope.customerModal = function (action) {

        var modalInstance = $uibModal.open({
            templateUrl: 'addNewCustomer.html',
            controller: 'CustomerModalInstanceCtrl',
            resolve: {
                customer: function () {
                    return $scope.customer;
                }
            }
        });

        modalInstance.result.then(function (customer) {
            //$scope.customer = customer;

            if(! customer.id) {
                Customer.save(customer, function(c){
                    $scope.customer = c;
                });
            } else {
                $scope.updateCustomer();
            }
        });
    };

    $scope.guitarModal = function () {

        var modalInstance = $uibModal.open({
            templateUrl: 'guitarModal.html',
            controller: 'GuitarModalInstanceCtrl',
            resolve: {
                customerGuitars: function () {
                    return $scope.customerGuitars;
                }
            }
        });

        modalInstance.result.then(function (guitar) {
            guitar.job_id = $scope.id;
            guitar.customer_id = $scope.customer.id;
            Guitar.save(guitar, function(c){
                if(!c.error) {
                    $scope.guitars.push(c);
                }

            });

        });
    };
     $scope.itemModal = function (guitar_id) {

            var modalInstance = $uibModal.open({
                templateUrl: 'itemModal.html',
                controller: 'ItemModalInstanceCtrl',
                resolve: {
                    guitars: function () {
                        return $scope.guitars;
                    },
                    guitar_id: function() {
                        return guitar_id;
                    }
                }
            });

            modalInstance.result.then(function (item) {
                item.job_id = $scope.job.id;
                Job.addItem(item, function(c){
                    if(!c.error) {
                        var i = new JobItem(c);
                        $scope.items.push(i);
                    }
                });
                if(item.another) {
                    $scope.itemModal(item.guitar_id);
                }

            });
     };
     $scope.completedModal = function () {

            var modalInstance = $uibModal.open({
                templateUrl: 'completedModal.html',
                controller: 'CompletedModalInstanceCtrl',
                resolve: {
                    customer: function () {
                        return $scope.customer;
                    }
                }
            });
            modalInstance.result.then(function (data) {
                Job.notify({job_id: $scope.job.id}, data, function(r){

                });
            });
     };
     $scope.emailModal = function (what) {
            var modalInstance = $uibModal.open({
                templateUrl: 'emailModal.html',
                controller: 'EmailModalInstanceCtrl',
                resolve: {
                    job: function () {
                        return $scope.job;
                    },
                    customer: function () {
                        return $scope.customer;
                    },
                    what: function () {
                        return what;
                    },
                    emailTemplate: function () {
                        return $scope.emailTemplate;
                    },
                }
            });
            modalInstance.result.then(function (data) {
                console.log(data);
                // Job.notify({job_id: $scope.job.id}, data, function(r){
                // });
            });
     };

    $scope.staffModal = function (action) {


        var modalInstance = $uibModal.open({
            templateUrl: 'staffModal.html',
            controller: 'StaffModalInstanceCtrl',
            resolve: {
                staff: function () {
                    return $scope.staff;
                }
            }
        });

        modalInstance.result.then(function (staff) {

            if(staff.id) {
                staff.job_id = $scope.id;

                Staff.save(staff, function(c){
                    staff.username = c.username;
                    staff.pivot = {
                        percent: staff.percent
                    };
                    $scope.staffs.push(staff);
                });
            }
        });
    };

    $scope.$watch('items', function() {
        var total = 0;
        if($scope.items.length) {
            $scope.items.forEach(function (item) {
                total += item.quantity * item.rate;
            });
        }
        $scope.jobTotal = total;
    }, true);

    $scope.$watch('job', function (newVal, oldVal) {
        if(oldVal.invoice_status && (newVal.invoice_status !== oldVal.invoice_status) && $scope.job.invoice_status == 'Paid' && $scope.job.amount_paid != $scope.jobTotal) {
            $scope.job.amount_paid = $scope.jobTotal;
        }
        if(oldVal.status && (oldVal.status != newVal.status) && newVal.status === 'Complete') {
            $scope.updateJob();
            $scope.completedModal();
        }
    }, true);


    $scope.updateJob = function() {
        $scope.startSaving();
        $scope.job.$update(function(){
            $scope.stopSaving();
        }, function(e) {
            $scope.errorSaving(e);
        });
    };

    $scope.updateCustomer = function() {
        $scope.startSaving();
        $scope.customer.$update(function(){
            $scope.stopSaving();
        }, function(e) {
            $scope.errorSaving(e);
        });
    };

    $scope.updateItem = function(i) {
        $scope.startSaving();
        $scope.items[i].$update(function(){
           $scope.stopSaving();
        }, function(e) {
            $scope.errorSaving(e);
        });

    };

    $scope.updateStaff = function(i) {
        $scope.startSaving();
        $scope.staffs[i].$update({job_id: $scope.id}, function(r){
            if(r.status == 'removed') {
                $scope.staffs.splice(i, 1);
            }
            $scope.stopSaving();
        }, function(e) {
            $scope.errorSaving(e);
        });
    };
    var win;
    $scope.printing = false;
    $scope.print = function(what) {
        $scope.printing = true;
        Job.pdf({job_id: $scope.id, what:  what}, function(r){
            $scope.printing = false;
            if(r.path) {
                if(win) win.close();
                win = window.open(r.path, "Title", "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=780, height=500, top=20, left=50");
                win.print();

                if ('matchMedia' in window) {
                    window.matchMedia('print').addListener(function(media) {
                        if (!media.matches) {
                            // $(win).on('mouseover', function(){
                                win.close();
                            // });
                        }
                    });
                } else {
                    $(window).on('afterprint', function(){
                        win.close();
                    });
                }
            }
        }, function(e) {
            $scope.printing = false;
            $scope.errorSaving(e);
        });
    };

    $scope.removeGuitar = function(i) {
        $scope.startSaving();
       Guitar.delete({job_id: $scope.id, id:  $scope.guitars[i].id}, function(r){
            if(r.status == 'removed') {
                $scope.guitars.splice(i, 1);
                $scope.stopSaving();
            }
        }, function(e) {
           $scope.errorSaving(e);
       });
    };

    $scope.canRemoveGuitar = function(i) {
        var guitar = $scope.guitars[i];
        var r = true;
        for(var i in $scope.items) {
            if(guitar.id == $scope.items[i].guitar_id) r = false;
        }
        return r;
    };

    $scope.searchCustomers = function(typed){
        var a = Customer.search({t:typed}).$promise.then(function(items) {
            return items;
        });
        return a;
    };
    $rootScope.allStaff = Staff.all().$promise.then(function(items) {
        $rootScope.allStaff = items;
    });


});


app.controller('CustomerModalInstanceCtrl', function ($scope, $uibModalInstance, customer) {

    $scope.tmpCustomer = customer && customer.id ? customer : {};

    $scope.ok = function () {
        $uibModalInstance.close($scope.tmpCustomer);
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
});

app.controller('StaffModalInstanceCtrl', function ($scope, $uibModalInstance, staff, Staff) {
    $scope.tmpStaff = {};
    $scope.tmpStaff.percent = 100;


    $scope.ok = function () {
        $uibModalInstance.close($scope.tmpStaff);
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
});


app.controller('GuitarModalInstanceCtrl', function ($scope, $uibModalInstance, customerGuitars, Guitar) {
    $scope.tmpGuitar = {};

    $scope.customerGuitars = customerGuitars;

    $scope.caseTypes =  [
        {value: 'Hard', text: 'Hard'},
        {value: 'Soft', text: 'Soft'},
        {value: 'Expanded', text: 'Expanded'},
        {value: 'Flight', text: 'Flight'},
        {value: 'None', text: 'None'}
    ];

    $scope.searchGuitars = function(typed){
        var a = Guitar.search({t:typed}).$promise.then(function(items) {
            return items;
        });
        return a;
    };

    $scope.searchGuitarModels = function(typed){
        var a = Guitar.searchModels({t:typed}).$promise.then(function(items) {
            return items;
        });
        return a;
    };


    $scope.ok = function () {
        $uibModalInstance.close($scope.tmpGuitar);
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
});

app.controller('CompletedModalInstanceCtrl', function ($scope, $uibModalInstance, Job, customer) {
    $scope.opts = {};
    if(customer.mobile_phone && customer.mobile_phone != "") {
        $scope.opts.sendSMS = true;
        $scope.opts.phoneNumber = customer.mobile_phone;
    }
    if(customer.email && customer.email != "") {
        $scope.opts.sendEmail = true;
        $scope.opts.emailAddress = customer.email;
    }
    $scope.ok = function () {
        //update
        $uibModalInstance.close($scope.opts);
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
});

app.controller('EmailModalInstanceCtrl', function ($scope, $uibModalInstance, job, what, customer, emailTemplate, Job) {
    $scope.opts = {};
    $scope.opts.emailTemplate = emailTemplate[what];
    $scope.sending = false;
    $scope.job = job;
    if(customer.email && customer.email != "") {
        $scope.opts.emailAddress = customer.email;
    }
    $scope.ok = function () {
        $scope.sending = true;
        Job.email({job_id: $scope.job.id, what: what, data:  $scope.opts}, function(r){
            $uibModalInstance.close($scope.opts);

        }, function(e) {
            alert('Error sending email');
            $scope.sending = false;
        });
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
});

app.controller('ItemModalInstanceCtrl', function ($scope, $uibModalInstance, JobItem, guitars, guitar_id) {
    $scope.tmpItem = null;
    $scope.guitars = guitars;
    $scope.guitar_id = guitar_id;
    console.log(guitar_id);
    $scope.searchItems = function(typed){
        var a = JobItem.search({t:typed}).$promise.then(function(items) {
            return items;
        });
        return a;
    };

       $scope.ok = function (another) {
           $scope.tmpItem.another = another;
           $scope.tmpItem.guitar_id = $scope.guitar_id;
        $uibModalInstance.close($scope.tmpItem, another);
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
});


app.directive('focus',

    function($timeout) {

        return {

            scope : {

                trigger : '@focus'

            },

            link : function(scope, element) {

                scope.$watch('trigger', function(value) {

                    if (value === "true") {

                        $timeout(function() {

                            element[0].focus();

                        });
                    }
                });
            }

        };

    }

);


app.run(function(editableOptions) {
    editableOptions.theme = 'bs3'; // bootstrap3 theme. Can be also 'bs2', 'default'
});

app.filter('exact', function() {
    return function(items, field) {
        var result = {};
        var i = 0;
        angular.forEach(items, function(value, key) {
            if(value.value == field.value) {
                result[i] = value;
                i++;
            }
        });
        return result;
    };
});

app.filter('dateToISO', function() {
    return function(input) {
        return Date.parse(input);
    };
});

app.filter('clean', ['$sce', function($sce){
    return function(text) {
        return $sce.trustAsHtml(text);
    };
}]);
