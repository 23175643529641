var nodendb = {};
$('.settings').each(function(){
   var name =  $(this).attr('name');
   nodendb[name] = $(this).attr('content');
});
nodendb.showMultipleButtons = function(b) {
   if(b == true) {
      $('[data-multiple-action]').addClass('disabled');
   } else {
      $('[data-multiple-action]').removeClass('disabled');
   }
};


$(document).on('ready pjax:success', function() {

   $('#shortcuts').dropdown();

   $('#calendar').fullCalendar({

      firstDay: 1,
      timeFormat: 'H:mm',
      googleCalendarApiKey: 'AIzaSyCfsx76Cg2_gQ7KX1uHATpqxemypi1kcJg',
      eventSources: [
         {
            googleCalendarId: nodendb.public_holidays,
            color: '#aaa',
            textColor: 'white'
         },
         {
            url: '/calender/jobs',
            color: 'yellow',
            textColor: 'black'
         },
         {
            url: '/calender/tasks',
            color: 'purple',
            textColor: 'white'
         },
         {
            url: '/calender/events/public'
         },
         {
            url: '/calender/events/private'
         }
      ],
      eventClick: function(event) {
         if(event.source.url == nodendb.public_holidays) {
            window.open(event.url, 'gcalevent', 'width=700,height=600');
         }else if(event.url) {
            $.pjax({url: event.url, container: '#main'})
         }
         return false;
      }

   });

   $('.link-row').on('click', function(e){
      e.preventDefault();
      var url = $(this).data('href');
      $.pjax({url: url, container: '#main'})
   });

   $('.task-action').on('click', function(e){
      e.stopPropagation();
   });

   var startDateTextBox = $('#starts_at');
   var endDateTextBox = $('#ends_at');

   if(startDateTextBox.length > 0 && endDateTextBox.length > 0) {
      $.timepicker.datetimeRange(
          startDateTextBox,
          endDateTextBox,
          {
             minInterval: (1000 * 60 * 60), // 1hr
             dateFormat: 'mm/dd/yy',
             timeFormat: 'HH:mm',
             controlType: 'select',
             oneLine: true,
             stepMinute: 1,
             minDate: new Date,
             start: {},
             end: {}
          }
      );
   }

   $('.datetime-picker').datetimepicker({
      controlType: 'select',
      oneLine: true,
      stepMinute:15,
      minDate: new Date
   });



   $('#checkAll').bind('change', function(){
      var c = $(this).prop('checked');
      $('.selectMe').prop('checked', c);
   });

   $("input[name=send_to]").bind('change', function(){
      var c = $(this).val();
      if(c == 'all') {
         $('.participants-group').addClass('hide');
         $('.selectMe').prop('checked', true);
      } else {
         $('.participants-group').removeClass('hide');
         $('.selectMe').prop('checked', false);
      }
   });

   if($('[data-multiple-action]').length) {

      $('input[type=checkbox]').bind('change', function(event){
         event.stopPropagation();
         var disabled = true;
         $('input[type=checkbox]').each(function(){
            if($(this).prop('checked') == true) disabled = false;
         });
         nodendb.showMultipleButtons(disabled);
      }).click(function(event){
         event.stopPropagation();
      });

   }

   $('#show-participants').bind('click', function(){
      $('.participants-group').toggleClass('hide')
   });


   if(typeof documentReady == 'function') documentReady()

   $(document).on('keydown', function(e){
      if(e.ctrlKey == true) {
         switch(e.keyCode) {
            case 39:
               e.preventDefault();
               $('#handle').trigger('click');
               break;
            case 38:
               e.preventDefault();
               $('#view-as').toggle();
               break;
         }
      }
   });

});


Storage.prototype.setObject = function(key, value) {
   this.setItem(key, JSON.stringify(value));
};

Storage.prototype.getObject = function(key) {
   var value = this.getItem(key);
   return value && JSON.parse(value);
};

